@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #c5f4e6;
  height: 100vh;
  color: #ece7e7;
}

main {
  margin-top: 7rem;
  text-align: center;
  height: 100vh;
}

h1,
h2,
h3,
p {
  color: #348f5d;
}
