/* Full screen loading */
.overlayWrapper {
  position: relative;
  min-height: 100vh;
  margin-bottom: 100px;
}

.viewBox {
  margin: 20px;
  border: 1px solid #064422;
  display: inline-block;
  border-radius: 20px;
  padding: 30px;
  background-color: #225c3f;
  box-shadow: inset 0 0 0.1em #fff, 0.2em 0.2em 0.2em rgba( 0, 0, 0, 0.3 );
  text-align: left;
  list-style: none;
}

